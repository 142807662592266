import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import MapPage from "../components/map/MapPage"
import { graphql } from "gatsby"
const GlobalPresence = () => {
  return (
    <Layout>
      <Seo title="Divas lift installations across the world" />
      <MapPage></MapPage>
    </Layout>
  )
}

export default GlobalPresence

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
