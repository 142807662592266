import React from "react"
import {
  ComposableMap,
  Geographies,
  Geography,
  Marker,
} from "react-simple-maps"
const geoUrl =
  "https://raw.githubusercontent.com/zcreativelabs/react-simple-maps/master/topojson-maps/world-110m.json"

const markers = [
  {
    markerOffset: -10,
    name: "London",
    coordinates: [-0.127758, 51.507351],
  },
  { markerOffset: 15, name: "Bordeaux", coordinates: [-0.57918, 44.837788] },
  { markerOffset: -15, name: "Umag", coordinates: [13.525721, 45.437206] },
  { markerOffset: -15, name: "Warsaw", coordinates: [21.01223, 52.229675] },
  { markerOffset: 15, name: "Tirana", coordinates: [19.818699, 41.327545] },
  { markerOffset: 15, name: "Naples", coordinates: [14.242906, 40.853687] },
  {
    markerOffset: 15,
    name: "La Valletta",
    coordinates: [14.514553, 35.898908],
  },
  {
    markerOffset: -15,
    name: "Skopjie",
    coordinates: [21.425435, 41.998129],
  },
  { markerOffset: 15, name: "Costanza", coordinates: [28.634814, 44.159801] },
  { markerOffset: -15, name: "Budapest", coordinates: [19.040235, 47.497912] },
  { markerOffset: 15, name: "Moscow", coordinates: [37.6173, 55.755826] },
  { markerOffset: 15, name: "Cairo", coordinates: [31.235712, 30.04442] },
  { markerOffset: 15, name: "Tripoli", coordinates: [13.191338, 32.887209] },
  { markerOffset: 15, name: "Algeri", coordinates: [3.058756, 36.753768] },
  { markerOffset: 15, name: "Praia", coordinates: [-23.52, 14.95] },
  { markerOffset: 15, name: "Cotonou", coordinates: [2.433333, 6.366667] },

  { markerOffset: 15, name: "Dhaka", coordinates: [90.412518, 23.810332] },
  {
    markerOffset: 15,
    name: "Ho chi min",
    coordinates: [106.629664, 10.823099],
  },
  { markerOffset: 15, name: "Hanoi", coordinates: [105.83416, 21.027764] },
  { markerOffset: 15, name: "Jakarta", coordinates: [106.845599, -6.208763] },
  { markerOffset: -20, name: "Sydney", coordinates: [151.209296, -33.86882] },
  {
    markerOffset: -15,
    name: "Victoria",
    coordinates: [144.785153, -37.471308],
  },
  { markerOffset: 15, name: "Perth", coordinates: [115.857047, -31.953513] },
  { markerOffset: 15, name: "Melbourne", coordinates: [146.315101, -39.02902] },

  {
    markerOffset: 15,
    name: "Mexico City",
    coordinates: [-99.133208, 19.432608],
  },
  { markerOffset: 15, name: "Kuwait City", coordinates: [47.481766, 29.31166] },
  { markerOffset: 15, name: "Dubai", coordinates: [55.270783, 25.204849] },
  { markerOffset: -15, name: "Jerusalem", coordinates: [34.851612, 31.046051] },
  { markerOffset: 15, name: "Amman", coordinates: [36.238414, 30.585164] },
  { markerOffset: -15, name: "Manama", coordinates: [50.553972, 26.2266894] },
  { markerOffset: 15, name: "San'a'", coordinates: [48.516388, 15.552727] },

  { markerOffset: 15, name: "Jeddah", coordinates: [39.192505, 21.485811] },

  { markerOffset: 15, name: "Riyadh", coordinates: [46.675297, 24.713552] },
  { markerOffset: 15, name: "Belize", coordinates: [-88.49765, 17.189877] },
  { markerOffset: 15, name: "Muscat", coordinates: [58.2844265, 23.5830766] },
  { markerOffset: 15, name: "Doha", coordinates: [51.371907, 25.2842534] },
  { markerOffset: 15, name: "Novigrad", coordinates: [13.561947, 45.315657] },
  {
    markerOffset: 15,
    name: "Santiago de Chile",
    coordinates: [-70.641997, -33.46912],
  },
  {
    markerOffset: -15,
    name: "Valparaiso",
    coordinates: [-71.620361, -33.045646],
  },
  {
    markerOffset: 15,
    name: "Panama City",
    coordinates: [-79.516667, 8.983333],
  },
  { markerOffset: -15, name: "San José", coordinates: [-84.090725, 9.928069] },

  {
    markerOffset: -15,
    name: "Punta cana",
    coordinates: [-68.405473, 18.58201],
  },

  {
    markerOffset: 15,
    name: "Santo Domingo",
    coordinates: [-69.95, 18.466667],
  },
]

const MapChart = ({ scale, rotate }) => {
  return (
    <>
      <ComposableMap
        projection="geoAzimuthalEqualArea"
        projectionConfig={{
          rotate: rotate,
          scale: scale,
        }}
      >
        <Geographies geography={geoUrl}>
          {({ geographies }) =>
            geographies.map(geography => (
              <Geography
                key={geography.rsmKey}
                fill="#EAEAEC"
                stroke="#D6D6DA"
                geography={geography}
                style={{
                  default: { outline: "none" },
                  hover: { outline: "none" },
                  pressed: { outline: "none" },
                }}
              />
            ))
          }
        </Geographies>
        {markers.map(({ name, coordinates, markerOffset }) => (
          <Marker key={name} coordinates={coordinates}>
            <circle r={5} fill="#F00" stroke="#fff" strokeWidth={1} />
            <text
              textAnchor="middle"
              y={markerOffset}
              style={{ fontSize: "16px", fill: "#5D5A6D" }}
            >
              {name}
            </text>
          </Marker>
        ))}
      </ComposableMap>
    </>
  )
}

export default MapChart
