import React, { useState } from "react"
import {
  Button,
  ButtonWrapper,
  ArrowForward,
  ArrowRight,
} from "../../styles/styled-component/Button"
import { BiMailSend } from "react-icons/bi"
import Container, {
  EndPageContainer,
} from "../../styles/styled-component/Container"
import InnerContainer from "../../styles/styled-component/InnerContainer"
import Section from "../../styles/styled-component/Section"
import MapChart from "./MapChart"
import { Trans } from "gatsby-plugin-react-i18next"
import "../../styles/typography.css"

const MapPage = () => {
  const [hover, setHover] = useState(false)
  const onHover = () => {
    setHover(!hover)
  }
  return (
    <>
      <Section bgColor="#f2f2f2" flex center minHeight>
        <Container flex center>
          <InnerContainer>
            <h1>
              <Trans>Divas in the world</Trans>
            </h1>
            <p>
              <Trans>Can't find your city? Help us add it to the map</Trans>
            </p>{" "}
            <ButtonWrapper>
              <Button
                to="/contact"
                bgColor="#0066ff"
                bgColorHover="#005ce6"
                borderRadius="24px"
              >
                <Trans>Contact</Trans>
              </Button>{" "}
            </ButtonWrapper>
          </InnerContainer>
        </Container>
      </Section>
      <Section flex center>
        <Container flex center>
          <InnerContainer>
            <h2>Europe</h2>
            <MapChart scale={750} rotate={[-20.0, -52.0, 0]}></MapChart>
          </InnerContainer>
        </Container>
        <Container flex center>
          <InnerContainer>
            <h2>SEA and Oceania</h2>
            <MapChart scale={450} rotate={[-120.0, 5.0, 0]}></MapChart>
          </InnerContainer>
        </Container>
        <Container flex center>
          <InnerContainer>
            <h2>South America</h2>
            <MapChart scale={550} rotate={[80.0, 5.0, 0]}></MapChart>
          </InnerContainer>
        </Container>
        <Container flex center>
          <InnerContainer>
            <h2>Arab Peninsula</h2>
            <MapChart scale={1300} rotate={[-45.0, -25.0, 0]}></MapChart>
          </InnerContainer>
        </Container>
      </Section>
      <Section flex bgColor="#f2f2f2">
        <EndPageContainer flex center direction="column">
          <BiMailSend size={124} />
          <h1 className="end-page-title">
            <Trans>Get in touch</Trans>
          </h1>
          <p>
            <Trans>
              Fill a inquiry or book an appointment with our sales team
            </Trans>
          </p>
          <Button
            to="/contact"
            bgColor="#0066FF"
            bgColorHover="#005ce6"
            borderRadius="24px"
            onMouseEnter={onHover}
            onMouseLeave={onHover}
          >
            <Trans>ASK US</Trans> {hover ? <ArrowForward /> : <ArrowRight />}
          </Button>
        </EndPageContainer>
      </Section>
    </>
  )
}

export default MapPage
