import styled from "styled-components"
import { Link } from "gatsby"
import { IoMdArrowForward } from "react-icons/io"

import { MdKeyboardArrowRight } from "react-icons/md"

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  @media screen and (max-width: 768px) {
    align-items: center;
    justify-content: ${props => (props.centerButton ? "center" : "start")};
  }
`
export const Button = styled(Link)`
  border-radius: ${props => props.borderRadius};
  background-color: ${props => props.bgColor};
  padding: 14px 24px;
  color: white;
  font-family: lato, sans-serif;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease-out;
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 320px) {
    padding: 14px;
  }
  &:hover {
    text-decoration: underline;
    transform: translateY(-2px);

    background-color: ${props => props.bgColorHover};
  }
`

export const SecondaryButton = styled(Button)`
  background-color: transparent;
  color: ${props => props.color};
  font-weight: 400;
  border: 2px solid;
  margin-left: 10px;
`

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: start;
`

export const ArrowForward = styled(IoMdArrowForward)`
  margin-left: 8px;
  font-size: 20px;
`

export const ArrowRight = styled(MdKeyboardArrowRight)`
  margin-left: 8px;
  font-size: 20px;
`

export default Button
